"use client";
import { useEffect, useRef, useState } from "react";
import CTALink from "@/components/CTA/ctaLink";
import type { NavData, NavProps } from "@/components/InternalNav/types";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { validateLocation } from "@/utils/functions/validateLocation";

const DegreeNav = ({
  data,
  cta,
  locationRestrictions,
  testVariant,
}: NavProps) => {
  const [isAtTop, setIsAtTop] = useState<boolean>(false);
  const entryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (entryRef.current) {
        const rect = entryRef.current.getBoundingClientRect();
        // Check if the element's top is at or above the viewport's top
        if (rect.top <= 0 && !isAtTop) {
          setIsAtTop(rect.top <= 0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Initial check in case the element is already at the top
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [entryRef]);

  if (!cta) {
    return null;
  }

  const navigation: NavData[] = data || [];
  const showNavigation = navigation.length > 0;

  const handleSelectChange = (selectedValue: string) => {
    if (selectedValue) {
      selectedValue = selectedValue.slice(1); //remove #
      const targetElement = document.getElementById(selectedValue);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "instant" });
      }
    }
  };

  const shouldShowNav =
    cta.show !== false &&
    validateLocation(
      locationRestrictions?.allowedIn || [],
      locationRestrictions?.blockedIn || [],
    );

  return (
    <div ref={entryRef} className="sticky top-0 z-50">
      <div className={`w-full bg-primary p-6 ${!isAtTop ? "hidden" : ""}`}>
        <div
          className={`container flex flex-col xl:flex-row justify-center items-center gap-6 ${showNavigation ? "xl:justify-between" : ""}`}
        >
          {showNavigation && (
            <>
              <nav className="hidden lg:flex gap-8 items-center">
                {navigation.map((item, index) => (
                  <a
                    key={index}
                    href={item.id}
                    className="text-base underline decoration-1 decoration-primary-foreground/75 underline-offset-1 text-primary-foreground hover:decoration-primary-foreground"
                  >
                    {item.name}
                  </a>
                ))}
              </nav>
              <div className="lg:hidden w-full flex flex-col sm:flex-row items-center justify-between [&>button]:bg-white [&>button]:text-lg">
                <Select onValueChange={handleSelectChange}>
                  <SelectTrigger>
                    <SelectValue placeholder={navigation[0]?.name} />
                  </SelectTrigger>
                  <SelectContent>
                    {/* Must find id's of selectable items based within the page content */}
                    {navigation.map((item, index) => (
                      <SelectItem
                        key={index}
                        value={item.id}
                        className="md:mx-2"
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </>
          )}
          {shouldShowNav && (
            <div className="flex flex-wrap justify-center items-center gap-4">
              <p
                className={`sm:text-lg text-primary-foreground mb-0 font-bold ${showNavigation ? "xl:text-base" : ""}`}
              >
                Get more information
              </p>
              <CTALink
                url={cta.url}
                label={cta.label}
                type="brand"
                size="xl"
                enable_tracking={testVariant === "redesign"}
                location="degree-detail-page"
                html_id="sticky-nav-cta"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DegreeNav;
