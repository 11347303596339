"use client";

import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useEffect,
  useRef,
} from "react";

import monarchConfig from "@/lib/cohesion/monarchConfig";
import { cohesion } from "@highereducation/cohesion-utils-react";

interface RuleState<T = unknown> {
  isLoading: boolean;
  value: T;
}

type State = Record<string, RuleState>;

interface RequestStartAction {
  type: "REQUEST_START";
  key: string;
}

interface RequestSuccessAction {
  type: "REQUEST_SUCCESS";
  key: string;
  value: unknown;
}

interface RequestFailureAction {
  type: "REQUEST_FAILURE";
  key: string;
}

type Action = RequestStartAction | RequestSuccessAction | RequestFailureAction;

// Define types for the context
interface MonarchRuleContextType {
  state: State;
  fetchRule: (
    ruleName: string,
    params: Record<string, unknown> | undefined,
  ) => void;
}

const MonarchRuleContext = createContext<MonarchRuleContextType | undefined>(
  undefined,
);

//https://github.com/RedVentures/cohesion-client-js/blob/3d1b5ee33e1c8275d2c58977206eda44759a0164/src/libs/monarch/utils.js#L69
export function updatePayloadWithPreviews(payload: Record<string, any>): void {
  const url = new URL(window.location.href);
  const monarchPreviewString = url.searchParams.get("monarchPreview");

  const isStrict = url.searchParams.get("monarchPreviewStrict") === "1";
  const previewTuples = monarchPreviewString
    ? monarchPreviewString.split(";").map((s) => s.split("."))
    : [];
  const previews: { [key: string]: string } = {};
  for (let i = 0; i < previewTuples.length; i++) {
    const previewTuple = previewTuples[i];
    if (i === 0 && previewTuple.length === 1) {
      // Allow the first tuple to implicitly be for the `trafficFlow`.
      previewTuple.unshift("trafficFlow");
    } else if (previewTuple.length !== 2) {
      // Otherwise, require all tuples to have exactly two parts.
      continue;
    }
    const [ruleId, returnValueId] = previewTuple;
    previews[ruleId] = returnValueId;
  }
  if (Object.keys(previews).length !== 0) {
    if (!payload._Monarch) payload._Monarch = {};
    if (!payload._Monarch.forceReturns) payload._Monarch.forceReturns = {};
    payload._Monarch.forceReturns = {
      ...payload._Monarch.forceReturns,
      ...previews,
    };
    if (!isStrict) {
      payload._Monarch.allowInvalidForcedReturns = true;
    }
  }
  const monarchDraftPreviewString = url.searchParams.get("monarchDraft");
  const draftPreviews = monarchDraftPreviewString
    ? monarchDraftPreviewString.split(";")
    : [];
  if (draftPreviews.length !== 0) {
    if (!payload._Monarch) payload._Monarch = {};
    if (!payload._Monarch.draftPreviewIds)
      payload._Monarch.draftPreviewIds = [];
    payload._Monarch.draftPreviewIds = [
      ...payload._Monarch.draftPreviewIds,
      ...draftPreviews,
    ];
  }
}

// Reducer function
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "REQUEST_START":
      return {
        ...state,
        [action.key]: { isLoading: true, value: undefined },
      };
    case "REQUEST_SUCCESS":
      return {
        ...state,
        [action.key]: { isLoading: false, value: action.value },
      };
    case "REQUEST_FAILURE":
      return {
        ...state,
        [action.key]: { isLoading: false, value: undefined },
      };
    default:
      return state;
  }
};

interface MonarchRuleProviderProps {
  children: ReactNode;
}

export const MonarchRuleProvider: React.FC<MonarchRuleProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, {});
  const initialized = useRef(false);
  const queue = useRef<
    Array<{ ruleName: string; params: Record<string, unknown> | undefined }>
  >([]);

  const processQueue = (
    cb: (params: {
      ruleName: string;
      params: Record<string, unknown> | undefined;
    }) => void,
  ) => {
    const uniqueQueue = Array.from(
      new Set(
        queue.current.map(({ ruleName, params }) =>
          JSON.stringify({ ruleName, params }),
        ),
      ),
    ).map((item) => JSON.parse(item));

    uniqueQueue.forEach(cb);
  };

  useEffect(() => {
    if (initialized.current) return;

    setTimeout(() => {
      if (!initialized.current) {
        processQueue(({ ruleName, params }) => {
          dispatch({
            type: "REQUEST_FAILURE",
            key: JSON.stringify({ ruleName, params }),
          });
        });
      }
    }, 2000);

    cohesion("ready", function () {
      initialized.current = true;
      processQueue(({ ruleName, params }) => {
        fetchRule(ruleName, params);
      });

      queue.current = [];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRule = async (
    ruleName: string,
    params?: Record<string, unknown> | undefined,
  ) => {
    const key = JSON.stringify({ ruleName, params });

    if (state[key] && state[key].isLoading) {
      return;
    }

    if (!initialized.current) {
      queue.current.push({ ruleName, params });
      return;
    }

    dispatch({ type: "REQUEST_START", key });

    try {
      const aliasSet = window.tagular?.("getAliasSet");

      let payload = {
        ...params,
        _Monarch: {
          cohesionPayload: {
            firstRequestUrl: window.location.href,
            webContext: window._Cohesion?.webContext,
          },
        },
      };
      updatePayloadWithPreviews(payload);
      const response = await fetch(
        `${monarchConfig.endpoint}/api/v1/evaluate/rule/${monarchConfig.sourceId}/${ruleName}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Monarch-Request-Url": window.location.href,
            "Session-Id": aliasSet?.sessionId as string,
            Token: monarchConfig.token,
            "Instance-Id": window._Cohesion?.instanceId as string,
            "Anonymous-Id": aliasSet?.anonymousId as string,
            "Make-Source-Uid": window._Cohesion?.sourceKey as string,
            Consent: "true",
          },
          body: JSON.stringify(payload),
        },
      );

      const result = await response.json();

      if (!result) {
        throw new Error("Network response was not ok");
      }

      dispatch({ type: "REQUEST_SUCCESS", key, value: result[ruleName] });
    } catch (error) {
      window.newrelic?.noticeError(error as Error, {
        monarchRuleName: ruleName,
      });
      console.error("Failed to fetch Monarch rule:", error);
      dispatch({ type: "REQUEST_FAILURE", key });
    }
  };

  return (
    <MonarchRuleContext.Provider value={{ state, fetchRule }}>
      {children}
    </MonarchRuleContext.Provider>
  );
};

export function useMonarchRule<T>(
  ruleName: string,
  params?: Record<string, unknown>,
): RuleState<T> {
  const context = useContext(MonarchRuleContext);
  if (!context) {
    throw new Error("useMonarchRule must be used within a MonarchRuleProvider");
  }

  const { state, fetchRule } = context;
  const key = JSON.stringify({ ruleName, params });

  // Store the monarch enabled state
  const isMonarchDisabled = () => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href);
      return url.searchParams.get("monarchEnabled") === "false";
    }
    return false;
  };

  useEffect(() => {
    // Skip fetching if monarch is disabled via query param
    if (isMonarchDisabled()) {
      return;
    }

    if (!state[key]) {
      fetchRule(ruleName, params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, ruleName, params, state, isMonarchDisabled]);

  // Return early with non-loading state if monarch is disabled
  if (isMonarchDisabled()) {
    return {
      isLoading: false,
      value: undefined as unknown as T,
    };
  }

  return (
    (state[key] as unknown as RuleState<T>) ?? {
      isLoading: true,
      value: undefined,
    }
  );
}
